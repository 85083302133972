export const descriptors = [
  "Agile",
  "Brash",
  "Brutal",
  "Cautious",
  "Charismatic",
  "Craven",
  "Cunning",
  "Eclipsed",
  "Enigmatic",
  "Evasive",
  "Feral",
  "Golem",
  "Haunted",
  "Jaded",
  "Hideous",
  "Hopeful",
  "Ill-fated",
  "Inquisitive",
  "Insane",
  "Lost",
  "Pious",
  "Relentless",
  "Resourceful",
  "Revenant",
  "Scholarly",
  "Stoic",
  "Titankin",
  "Vengeful",
  "Wise",
];

export const archetypes = ["Hunter", "Savant", "Ward", "Negotiator"];

export const foci = [
  "Balances the Scales",
  "Bears the Mark of the Beast",
  "Carries a Quiver",
  "Communicates with Spirits",
  "Conjures Flame",
  "Dances with Blades",
  "Descends into Rage",
  "Embraces the Chaos",
  "Follows No Laws",
  "Looks for Trouble",
  "Needs No Weapon",
  "Paints Illusions",
  "Plunders Tombs",
  "Protects the Weak",
  "Raises the Dead",
  "Refines Essence",
  "Rides the Storm",
  "Scavenges",
  "Seeks the Forbidden Truth",
  "Sings the Songs of Ruin",
  "Siphons Essence",
  "Spins Webs of Deceit",
  "Stands Unyielding",
  "Survives Against All Odds",
  "Summons Weapons from Memory",
  "Throws With Deadly Accuracy",
  "Vanquishes Monsters",
  "Walks the Crumbling Path",
  "Walks Unseen"
];

type DescriptionMap = {
  [key: string]: string;
};

export const DescriptorDescriptionsMap = {
  "Agile": "You possess unparalleled speed and agility—traits that make you a deadly opponent and a valuable asset to any team. Your nimble movements allow you to navigate treacherous environments with ease, evade attacks, and strike with precision. The grace with which you move often leaves others in awe, as if they are witnessing a dancer or acrobat in combat.",
  "Brash": "You’re a self-assertive sort, confident in your abilities, energetic, and perhaps a bit irreverent toward ideas that you don’t agree with. Some people call you bold and brave, but those you’ve put in their place might call you puffed up and arrogant. Whatever. It’s not in your nature to care what other people think about you, unless those people are your friends or family. Even someone as brash as you knows that friends sometimes have to come first.",
  "Brutal": "You are a force to be reckoned with, embodying raw power and brutal strength. Your physical prowess is unmatched, allowing you to overpower opponents with ease. You have a no-nonsense approach to combat, favoring direct and forceful tactics over finesse.",
  "Cautious": "You are known for your cautious nature and meticulous approach to every situation. You analyze every detail, weigh the risks, and plan your actions carefully. You prefer to avoid unnecessary dangers and make informed decisions based on thorough observation and assessment. Your cautious mindset has kept you alive in the unforgiving world of Eldraia.",
  "Charismatic": "Your presence commands attention whenever you enter a room—people are drawn to you like moths to a flame. Your charisma is not about physical attractiveness; it is an aura of confidence, charm, and magnetism that captivates those around you. Your words are persuasive, your gestures are captivating, and your personality is larger than life.",
  "Craven": "You are known for your craven nature, always seeking safety and avoiding unnecessary risks. You have a knack for assessing dangerous situations and finding the quickest escape route. While some may see you as a coward, you prefer to think of yourself as a survivor. After all, you can't accomplish anything if you're dead.",
  "Cunning": "Your sharp mind and cunning nature make you a master manipulator and strategist. You always seem to have an answer for every problem, finding creative solutions even in the most dire situations. Your ability to think several steps ahead often catches others off guard, allowing you to outwit opponents both physically and mentally.",
  "Eclipsed": "Your exposure to overwhelming amounts of titan essence has transformed your body and mind, granting you unique abilities but also subjecting you to unpredictable mutations and unstable emotions. You are both feared and revered for these changes, seen as both a potential savior and a ticking time bomb.",
  "Enigmatic": "You are a mystery—a puzzle waiting to be solved. Your true intentions and motivations remain elusive, even to those closest to you. You possess hidden knowledge, ancient secrets, or enigmatic powers that set you apart from others. Your enigmatic nature makes you both intriguing and intimidating, leaving others curious about your past and fearful of your potential.",
  "Evasive": "You possess uncanny reflexes and a keen sense of anticipation—traits that make you incredibly difficult to hit in combat. You are like a shadow, always one step ahead of your opponents' attacks. Your evasive nature allows you to weave through danger with ease, avoiding blows and striking back when the opportunity arises.",
  "Feral": "Your connection to nature runs deep—so deep that it has transformed you into something more than human. You possess animalistic traits—sharp instincts, heightened senses, and primal ferocity—that set you apart from others. The wilderness is your home, and you feel most alive when roaming its untamed expanses.",
  "Golem": "You are not human—you have been transformed into a living construct made from a blend of titan essence and other materials such as stone, metal, or wood. Your sturdy frame and resilient nature make you a formidable force on the battlefield. You possess a mechanical efficiency and strength that sets you apart from mere mortals.",
  "Haunted": "You carry the weight of a dark past, haunted by memories and spirits that refuse to let you rest. Your connection to the world of the dead grants you glimpses into the ethereal realm and abilities that others cannot comprehend. The spirits that surround you are both your allies and your tormentors, guiding you towards your destiny while also reminding you of your failures.",
  "Jaded": "You have seen things that most people cannot even fathom—horror, despair, and the darkest corners of Eldraia's shattered world. These experiences have left an indelible mark on your soul, hardening you against the cruelties of the world. Your jaded exterior hides a survivor's spirit—a relentless determination to keep going despite the odds.",
  "Hideous": "Your appearance is far from conventional notions of beauty; it is grotesque, repulsive even. The essence that flows within you has warped your physical form into something that others find abhorrent. Despite this, your hideous exterior grants you unique abilities and a certain degree of immunity to mundane judgments. Your true strength lies beneath your repulsive facade, and those who dare to underestimate you will find themselves at a distinct disadvantage.",
  "Hopeful": "You are a beacon of hope in the darkness, an optimist in a world filled with despair. Despite the bleakness that surrounds you, you refuse to succumb to the despair that plagues others. Your unyielding spirit and unwavering belief in a brighter future inspire those around you and drive you to fight for a better tomorrow.",
  "Ill-fated": "You are marked by a curse of ill fortune, destined to face constant adversity and misfortune. No matter how hard you try, the universe seems to conspire against you, throwing obstacles in your path and turning simple tasks into complex challenges. Despite these setbacks, you have learned to adapt and persevere, finding ways to overcome the seemingly insurmountable odds stacked against you.",
  "Inquisitive": "You possess an insatiable curiosity—a burning desire to unravel the mysteries of the world around you. Your thirst for knowledge knows no bounds, and you will go to great lengths to uncover the secrets hidden within Eldraia's shattered landscapes. Your keen intellect and analytical mind make you a valuable asset in deciphering ancient writings, understanding titan essence, and uncovering forgotten truths.",
  "Insane": "Your mind is a labyrinth of madness—an unfathomable abyss where reality intertwines with delusion. The horrors witnessed in Eldraia have shattered your sanity, leaving behind fragments of reason mixed with twisted perceptions. While your grasp on reality may be tenuous at best, there is an unpredictable power within your madness that can turn the tide of battle or confound your enemies.",
  "Lost": "You are lost, both physically and emotionally. The cataclysmic events that brought about the fall of the titans left you displaced and disconnected from the world. You wander through Eldraia, searching for purpose and a sense of belonging. Your experiences have made you resilient and observant, but also haunted by the memories of what was lost.",
  "Pious": "You view the fall of the titans as a divine event, a testament to the gods' will and an indictment of humanity's hubris. You wholeheartedly believe in the sanctity of titan essence and see your role as a titan reaper as a holy duty. Your unwavering faith gives you strength and purpose, but it may also blind you to other perspectives.",
  "Relentless": "You possess an unparalleled determination and unwavering resolve. No obstacle is too great to overcome; no enemy is too powerful to defeat. You push yourself beyond physical and mental limits, never giving up until you achieve victory. Your relentless pursuit of your goals inspires those around you and ensures that you always stand tall in the face of adversity.",
  "Resourceful": "You possess a knack for finding solutions to problems through unconventional means. Your ingenuity and adaptability allow you to make do with limited resources and overcome obstacles that would otherwise be insurmountable. Whether it's repurposing mundane objects as tools or using your surroundings to your advantage, your resourcefulness makes you an invaluable asset to any group.",
  "Revenant": "Raised from death by titan essence, you teeter on the border between life and death. Your undead existence grants you strength and resilience beyond mortal limits but it also fills you with an insatiable hunger for more essence. Balancing your need for power against maintaining control over your own mind is a constant struggle.",
  "Scholarly": "You possess an insatiable thirst for knowledge and an unquenchable curiosity about the world around you. Your extensive studies have granted you a deep understanding of various subjects, ranging from ancient lore to natural phenomena. You excel at deciphering cryptic texts, analyzing complex systems, and unraveling enigmatic puzzles.",
  "Stoic": "You possess an unwavering resolve and an unshakeable calmness even in the most dire circumstances. Your emotions are kept tightly under control, never clouding your judgment or decision-making. This stoic nature grants you unparalleled focus and resilience, making you immune to the distractions that plague others.",
  "Titankin": "You are one of the blessed few who bear the bloodline of the titans themselves. This connection grants you enhanced physical abilities and an innate affinity for titan essence. Your heritage is both a gift and a burden, as it draws attention from those who seek to control or exploit your power.",
  "Vengeful": "You have been wronged in the past and are driven by a burning desire for revenge. Betrayal, loss, humiliation – whatever the cause, it has left a deep scar on your soul that can only be healed by retribution. Your relentless pursuit for justice – or vengeance – fuels your every action.",
  "Wise": "Your years of experience and accumulated knowledge have granted you unparalleled wisdom. Your insights and understanding of the world make you a valuable asset to any group. Your advice and guidance are sought after, as your wisdom offers a unique perspective on situations.",
} as DescriptionMap;

export const FociDescriptionsMap = {
  "Balances the Scales": "You right wrongs, bringing justice to the chaotic and lawless world of Eldraia. As a master of martial combat and tactical strategy, you strike fear into the hearts of your enemies while inspiring your allies with unwavering resolve.",
  "Bears the Mark of the Beast": "You bear the mark of a powerful and primal creature through your bloodline, granting you the unique ability to shift into a powerful beast-like creature and a connection to the wild. As the embodiment of nature's wrath, you are a force to be reckoned with.",
  "Carries a Quiver": "You are deadly with a bow, relying on your accuracy and precision to strike down foes from afar. Your quiver is filled with arrows imbued with the power of titan essence, enhancing their potency and granting you supernatural abilities.",
  "Communicates with Spirits": "You have the ability to perceive and interact with spirits, the residual energy of deceased beings, and other supernatural entities. Your connection to the spirit realm grants you unique abilities and insights, but it also exposes you to dangers unseen by most.",
  "Conjures Flame": "You possess a deep connection to the elemental forces of fire. Through your mastery of pyromancy, you can manipulate and summon flames at will, scorching your enemies and leaving devastation in your wake.",
  "Dances with Blades": "You are a master of close-quarters combat, wielding blades with unparalleled skill and agility. Your movements are fluid and precise, allowing you to strike swiftly and gracefully with deadly accuracy.",
  "Descends into Rage": "You are a tempest of fury and vengeance, embodying the wrath of the fallen titans themselves. When provoked, your rage consumes you, unleashing a devastating storm of raw power upon your enemies.",
  "Embraces the Chaos": "You are an agent of chaos, reveling in the unpredictability and ever-changing nature of the world. You harness the power of entropy, using it to disrupt order and manipulate reality itself.",
  "Follows No Laws": "Chains and shackles mean nothing to you. Society's chains do not bind you either, as you follow your own code–or no code at all.",
  "Looks for Trouble": "You possess an insatiable curiosity and an uncanny knack for finding trouble wherever it may lurk. You thrive on the thrill of danger and are always eager to explore new, treacherous environments.",
  "Needs No Weapon": "You have honed your body to become your most lethal weapon. Through intense training and discipline, you have become a master of unarmed combat, relying solely on the raw power and agility of your own physical form.",
  "Paints Illusions": "You possess a unique ability to manipulate and shape the perception of others. Through your mastery of illusions and trickery, you can create fantastical scenes, deceive enemies, and even alter the course of reality itself.",
  "Plunders Tombs": "You are an expert at navigating the treacherous depths of titan carcasses and ancient ruins, seeking out lost treasures and forgotten knowledge. Your skills allow you to uncover hidden secrets and avoid deadly traps.",
  "Protects the Weak": "You are a defender of the innocent, sworn to protect those who cannot protect themselves. With your unwavering courage and steadfast resolve, you stand as a beacon of hope in the darkest of times.",
  "Raises the Dead": "You are familiar with the forbidden magics that meddle with the boundary between life and death. You have learned how to infuse titan essence into fallen creatures, coaxing them back into a semblance of life. Your reanimated allies follow your commands without question but are not as capable as they were in life.",
  "Refines Essence": "You possess the ability to refine and purify titan essence, transforming it into potent artifacts of immense power. Your skill in the arcane arts allows you to harness the raw energy of the titans and shape it into objects that can be wielded by others.",
  "Rides the Storm": "You are attuned to the chaotic and volatile essence that permeates Eldraia. You harness the power of storms, controlling lightning, wind, and other elemental forces to devastate your enemies.",
  "Scavenges": "You possess a keen eye and a knack for uncovering hidden treasures and valuable resources in the wastelands of Eldraia. Your scavenging skills allow you to find useful items, salvage materials, and discover forgotten secrets.",
  "Seeks the Forbidden Truth": "You are driven to uncover the secrets that lie hidden beneath the surface of Eldraia. You possess a relentless curiosity and an insatiable thirst for knowledge, even if it means delving into forbidden realms.",
  "Sings the Songs of Ruin": "You possess a haunting and melancholic voice, capable of weaving powerful spells and invoking ancient magic through song. Through your songs, you can channel the destructive power of the titans, leaving devastation in your wake.",
  "Siphons Essence": "You have honed your ability to siphon and manipulate titan essence, allowing you to extract it more efficiently and utilize its raw power in creative ways.",
  "Spins Webs of Deceit": "You are a master of manipulation and deception, using your cunning and wit to outsmart your enemies and manipulate situations to your advantage.",
  "Stands Unyielding": "You are a bastion of strength and endurance, able to withstand even the most relentless onslaughts. You rely on sheer resilience and indomitable willpower to endure in the face of overwhelming odds.",
  "Survives Against All Odds": "You are a survivor through and through, able to endure the harshest of conditions and come out on top. Your ability to adapt and persevere in the face of overwhelming odds is unmatched.",
  "Summons Weapons from Memory": "You possess the unique ability to manifest weapons from your memories and wield them with deadly precision. Through intense focus and concentration, you summon spectral weapons that are extensions of your own will.",
  "Throws With Deadly Accuracy": "You have an uncanny ability to throw weapons with deadly precision, striking your targets with unerring accuracy. Your aim is unmatched, and your projectiles always find their mark.",
  "Vanquishes Monsters": "You are a master of hunting down and slaying the monstrous creatures that roam Eldraia. Through your years of experience and expertise, you have developed unique skills and techniques that allow you to track, engage, and ultimately vanquish these deadly foes.",
  "Walks the Crumbling Path": "You are an expert at navigating treacherous terrain and crumbling structures. You possess an uncanny sense of balance and agility, allowing you to traverse even the most precarious of paths.",
  "Walks Unseen": "You move with an unnatural silence, your presence like a faint echo lost in the Dusk. Shadows seem to bend to your whims, allowing you to blend into the background and navigate terrain unnoticed.",
} as DescriptionMap;

export type Character = {
  descriptor: string;
  archetype: string;
  focus: string;
};

export const getArticle = (word: string): string => {
  const firstLetter = word.charAt(0).toLowerCase();
  return ["a", "e", "i", "o", "u"].includes(firstLetter) ? "an" : "a";
};

export const getCharacterPrompt = (character: Character | null) => {
  if (!character) {
    return "";
  }
  const { descriptor, archetype, focus } = character;
  const article = getArticle(descriptor);
  const charStatement =  `You are ${article} ${descriptor.toLowerCase()} ${archetype.toLowerCase()} who ${focus.toLowerCase()}.`;
  const prompt = `
  ${charStatement}

  ${DescriptorDescriptionsMap[descriptor]}

  ${FociDescriptionsMap[focus]}
  `;

  return prompt;
};

export const getCharacter = (): Character => {
  const descriptor =
    descriptors[Math.floor(Math.random() * descriptors.length)];
  const archetype = archetypes[Math.floor(Math.random() * archetypes.length)];
  const focus = foci[Math.floor(Math.random() * foci.length)];

  return {
    descriptor,
    archetype,
    focus,
  };
};
