import { useState } from "react";
import styled from "styled-components";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { DarkTheme, BaseProvider } from "baseui";
import { toaster, ToasterContainer } from "baseui/toast";
import "./App.css";
import { generateCharacter, mockGenerateCharacter } from "./api";
import { RunicLoader } from "./RunicLoader";
import { GenerateButton } from "./GenerateButton";
import { Header } from "./Header";
import { getCharacterPrompt, getCharacter, Character } from "./character";
import { CharacterPrompt } from "./CharacterPrompt";
import { SettingsModal } from "./Settings";
import { Menu } from "baseui/icon";

const engine = new Styletron();

const GenerateContainer = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CharacterContainer = styled.div`
  padding: 20px;
  text-align: left;
`;

const MenuButtonContainer = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
`;

function App() {
  const [character, setCharacter] = useState<Character | null>(null);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [customCharacterDescriptor, setCustomCharacterDescriptor] =
    useState("");
  const [customCharacterArchetype, setCustomCharacterArchetype] = useState("");
  const [customCharacterFocus, setCustomCharacterFocus] = useState("");

  const generate = async () => {
    setLoading(true);
    setCharacter(null);
    setDescription("");
    const character = getCharacter();
    
    // if any custom character settings are set, use those instead
    if (customCharacterDescriptor !== "") {
      character.descriptor = customCharacterDescriptor;
    }
    if (customCharacterArchetype !== "") {
      character.archetype = customCharacterArchetype;
    }
    if (customCharacterFocus !== "") {
      character.focus = customCharacterFocus;
    }

    const prompt = getCharacterPrompt(character);
    try {
      const response = await generateCharacter(prompt);
      // get text from response using Gpt3ApiResponse type
      console.log(response.choices[0].message.content);
      setCharacter(character);
      let description = response.choices[0].message.content;
      // remove the last paragraph to prevent last sentence from being cut off
      description = description.substring(0, description.lastIndexOf("\n"));
      setDescription(description);
    } catch (error: any) {
      toaster.negative(
        "Error generating character. " +
          error.message +
          ". Try using the upper right menu to enter your OpenAI API key."
      );
    }
    setLoading(false);
  };

  const closeSettings = (isOpen: boolean) => {
    setIsSettingsOpen(isOpen);
  };

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={DarkTheme}>
        <SettingsModal
          isOpen={isSettingsOpen}
          setIsOpen={closeSettings}
          customCharacterDescriptor={customCharacterDescriptor}
          customCharacterArchetype={customCharacterArchetype}
          customCharacterFocus={customCharacterFocus}
          setCustomCharacterDescriptor={setCustomCharacterDescriptor}
          setCustomCharacterArchetype={setCustomCharacterArchetype}
          setCustomCharacterFocus={setCustomCharacterFocus}
        />
        <ToasterContainer autoHideDuration={5000} />

        <div className="App">
          <MenuButtonContainer
            onClick={() => {
              setIsSettingsOpen(true);
            }}
          >
            <Menu color="white" size={24} />
          </MenuButtonContainer>
          <div>A Character Generator for</div>
          <Header />
          <GenerateContainer>
            {loading ? <RunicLoader /> : <GenerateButton generate={generate} />}
          </GenerateContainer>
          <CharacterContainer>
            <CharacterPrompt character={character} />
            {description.split("\n").map((t, k) => {
              return <p key={k}>{t}</p>;
            })}
          </CharacterContainer>
        </div>
      </BaseProvider>
    </StyletronProvider>
  );
}

export default App;
