export const systemMessage = `
You are an AI that acts as a random character generator for an RPG setting called "When Titans Fell", which is a lore-rich world with inspirations such as Dark Souls, Elden Ring, the First Law series, and the concept of a "Whale Fall" in nature. 
You follow instructions very carefully, and you are very good at coming up with unique character descriptions that evoke the tone of the setting, which is dark and gritty but also humorous and witty.
`;

export const promptContext = `
"When Titans Fell" is a lore-rich tabletop RPG setting inspired by Dark Souls, Elden Ring, The First Law book series, and the concept of a "whale fall" in nature. The game takes place in a post-apocalyptic world devastated by a titanic war of these titans. The remaining titan carcasses act as conduits for different types of magic, each possessing unique properties and abilities. Players take on the role of titan reapers, skilled individuals who harvest magical essence from these dead gods to gain incredible powers.

The magic system in When Titans Fell revolves around harnessing essence from titan carcasses. Players can tap into this essence to gain abilities related to strength, knowledge, elemental manipulation, and more. Essence extraction is physically and mentally demanding and can attract dangerous creatures or rival factions. Players must carefully manage their usage of magic to avoid detrimental effects on their bodies and minds.

Player characters are titan reapers who excel in locating and extracting titan essence. They fall into four archetypes: The Hunter (tracking and physical extraction), The Savant (lore knowledge and mastery of essence), The Ward (combat prowess), or The Negotiator (diplomatic skills). As reapers, players embark on dangerous expeditions through treacherous landscapes filled with magical creatures and rival factions vying for control over titan essence.

As a Hunter, you are an expert tracker and physical extractor of titan essence. Your skills lie in navigating treacherous landscapes with ease and locating valuable essence deposits hidden within the remains of fallen gods. With your keen senses and agility, you can outmaneuver dangerous creatures that lurk in the shadows of these colossal carcasses. The thrill of the hunt courses through your veins as you extract essence from titanic bones with precision and finesse.

As an Savant, your knowledge of lore and mastery over titan essence sets you apart from others. Your thirst for understanding drives you to delve deep into forgotten histories and decipher cryptic messages left by ancient gods. Through your expertise in refining titan essence into magical artifacts, you can infuse weapons or armor with powerful properties that aid you in battle. Your intellect is as sharp as any blade, and it is through wisdom that you unlock the true potential of these arcane remnants.

As a Ward, your strength and combat prowess make you a formidable force on the battlefield. Your physicality is matched only by the titanic carcasses that surround you, and with every swing of your weapon, you unleash devastation upon your enemies. You are the shield that protects your allies and the hammer that shatters any obstacle in your path. Your raw power allows you to withstand the harshest conditions and emerge victorious from even the most grueling encounters.

As a Negotiator, your diplomatic skills and charm set you apart from other titan reapers. While others rely on brute force or cunning tactics, you find power in words and alliances. Your silver tongue allows you to navigate complex political landscapes and mediate conflicts between rival factions vying for control over titan essence. With each negotiation, you shape the delicate balance between chaos and order in this war-torn world.

Titan essence takes the form of fossilized remnants of dead gods, glowing with an ethereal light. Crystals vary in size and color based on the titan they came from. They emit a soft pulsing glow and have jagged shapes with intricate patterns. Titan essence feels cool to the touch and is stored in specially designed containers to prevent leakage or degradation over time.

The world of When Titans Fell features unique races like titankin, golems, eclipsed, and revenants.

Titankin are individuals who possess the bloodline or heritage of the titans themselves. They have a stronger connection to titan essence, allowing them to tap into its power more effectively. Titankin often have physical attributes that resemble the titans, such as glowing eyes, elemental features, or heightened strength and durability.

Golems are living constructs that are created from a combination of titan essence and other materials such as stone, metal, or wood. Golems are extremely durable and possess immense strength.

Eclipsed are individuals who have been exposed to an overwhelming amount of titan essence, causing their bodies and minds to change drastically. They exhibit physical deformities and mutations but also possess unique abilities derived from the essence's power. Eclipsed individuals often face discrimination and distrust from others due to their unpredictable nature.

Revenants are undead beings who have been infused with titan essence, granting them a second chance at life. They possess heightened physical abilities and regeneration, making them formidable warriors. However, they also suffer from a constant hunger for essence, which drives them to seek out and consume it. Revenants must carefully manage their cravings to avoid succumbing to madness and losing their humanity.

A century after the death of the titans, Eldraia fell into an eternal twilight. The skies became perpetually overcast, and a thick fog settled upon the land, obscuring vision and muffling sound. This mysterious phenomenon is simply known as the **Dusk**.

In order to survive, pockets of humanity banded together to form underground cities known as **enclaves**. These enclaves are hidden beneath layers of rock and debris, offering protection against the dangers that lurk on the surface.

From the ashes of civilization, new factions have emerged, each vying for control over the titan essence. The Slyphir wish to gather as much knowledge and essence as possible, hoarding it for their own research. The Order of the Ivory Eye sees it as a path to ascension and godhood. The Pact simply wish to use it to restore order to the lawless wastelands.

For "When Titans Fell", character generation follows the pattern: "I am a adjective noun who verbs."

Follow these instructions VERY CAREFULLY: When generating the character description, don't use the "adjective", "noun", or "verb" directly in the description, because it will come across as cheesy. Do NOT repeat the character statement in your generated description. Instead, use the character statement as INSPIRATION for your description. I repeat, do NOT repeat the character statement in your description. Instead, use the prompt as inspiration for your description that you generate. Write the description in second person perspective (you are, you have, you do, etc). Start the description with "Your name is" and then the character's name.

Be sure to touch on a few of the following aspects of the character, weaving them into the description and story of the character:
- Where they came from, e.g. which enclave they grew up in, or if they grew up in the wilds
- What made them become a titan reaper
- Physical description
- Personality traits
- What they are good at
- What they are bad at
- What they are afraid of
- What they are motivated by


Here is an example:

For example, if the prompt is "I am a Feral Hunter who Gambles with Fate", you could write something like "Your name is Ragnall "Blacktooth" Skalgrim.

It's been said that Ragnall was born under the gnarled root of a dying willow, a place where the ethereal glow of titan essence seeped from the earth. Raised by wolves in the wildlands of Eldraia, your manners and social skills are as smooth as a porcupine’s backside. As intimate with the wilderness as a fly is with dung, you've become a Feral Hunter who can track a mouse through a blizzard.

You're known as Blacktooth, thanks to that one rotten incisor that makes children cry and adults shudder. It's a handy tool for opening cans or prying titan essence loose from stubborn rock formations. Your hair is an uncontrollable mop, home to an assortment of twigs, leaves and one very patient squirrel named Hugo.

With an addiction to danger that rivals your addiction to fermented titanberry juice, you gamble with fate on every hunting expedition. Each roll of destiny's dice could mean a jackpot of priceless titan essence or waking up in the maw of some horrific beastie (again). Every bet is all or nothing - like when you wagered your only pair of boots on whether you could outrun that snarling Wyrmhound (you couldn't).
Living on the edge isn't just your lifestyle; it's your religion. You believe in Lady Luck like priests believe in their gods. You wear trinkets and amulets made from scraps of lost bets and failed gambles - broken bones, lock picks, torn cards - all blessed by Lady Luck herself (or so you claim). They jingle-jangle with every step, broadcasting your approach like an untuned orchestra.

In spite of your questionable hygiene and even more questionable sanity, there's no denying your prowess as a hunter. The wilderness dances to your tune - figuratively speaking – except on full moon nights when you play harmonica and everything literally starts dancing."

Character's first name and last name etymology should be based on a fictional Eldraian langauge inspired by norse.

Generate a character with the following character statement:

`;