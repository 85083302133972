// Create functions to call the GPT-3.5 API

import axios, { AxiosError } from "axios";

import { systemMessage, promptContext } from "./context";

const OPEN_AI_COMPLETIONS_URL = "https://api.openai.com/v1/chat/completions";

export type Gpt3ApiResponse = {
  id: string; // Unique identifier for the API call
  model: string; // The GPT-3 model used for the response
  prompt: string; // The input prompt given to the API
  created: string; // Timestamp indicating when the response was created
  choices: {
    index: number;
    message: {
      role: string;
      content: string;
    };
    finish_reason: string;
  }[];
};


export const mockResponse = `
Your name is Kael Voss.

You were not born with a silver spoon in your mouth, nor were you blessed with the luxury of a carefree childhood. No, you grew up in the shadowy underbelly of Eldraia, amidst the grime and desperation of the southern enclave known as Umbra's Refuge. It was a place where survival meant staying one step ahead of your adversaries, where each day was a merciless battle for scraps in a world that seemed to revel in misery.

But you, Kael Voss, were different. While others succumbed to the despair that blanketed the land like a suffocating fog, you embraced it. Your inquisitive nature led you down dark and winding paths, seeking answers in forgotten tombs and crumbling ruins buried beneath layers of decay. You reveled in the thrill of uncovering ancient secrets and collecting relics that whispered tales of bygone eras.

As an Inquisitive Hunter, your sharp intellect and insatiable curiosity set you apart from your peers. You possess an uncanny ability to navigate treacherous landscapes and discern hidden truths from cryptic clues. With each tomb plundered, you unlock fragments of knowledge that may hold the key to Eldraia's shattered past.

But beware, Kael Voss, for your unquenchable thirst for insight may lead you down paths best left untraveled. The titanic echoes of fallen gods resonate through every dusty corridor you traverse, their presence a constant reminder of the price paid for such forbidden knowledge.

Armed with your trusty bow and keen senses honed through countless encounters with deadly creatures lurking within the tombs, you stalk through forgotten chambers with determination etched upon your face. You are driven by an insatiable hunger to understand what brought about this cataclysmic war between titans and to unveil truths that may reshape Eldraia's destiny.

So, buckle your boots, tighten your grip on that bowstring, and prepare to delve into the depths of Eldraia's forgotten tombs. As an Inquisitive Hunter who plunders tombs, you stand at the precipice of discovery and danger, where every step forward may unlock both the salvation and damnation of a world in ruins.
`;

//  Create a mock function that returns a static response after a delay
export const mockGenerateCharacter = async (
  prompt: string
): Promise<Gpt3ApiResponse> => {
  // create reponse
  const response = {
    id: "cmpl-3Z5Z5Y5Z5Y5Z5Y5Z5Y5Z5Y5",
    model: "gpt-3.5-turbo",
    prompt: prompt,
    created: "2021-10-20T18:00:00.000000Z",
    choices: [
      {
        index: 0,
        message: {
          role: "assistant",
          content: mockResponse,
        },
        finish_reason: "stop",
      },
    ],
  };
  // delay
  await new Promise((resolve) => setTimeout(resolve, 1000));
  // return response
  return response;
};

// Create a function to call the GPT-3.5 API
export const generateCharacter = async (
  prompt: string
): Promise<Gpt3ApiResponse> => {
  // get api key from local storage
  const API_KEY = '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';
  // create headers
  const headers = {
    Authorization: `Bearer ${API_KEY}`,
    "Content-Type": "application/json",
  };
  // create data
  const data = {
    model: "gpt-3.5-turbo",
    max_tokens: 500,
    temperature: 1,
    frequency_penalty: 0.5,
    messages: [
      {
        role: "system",
        content: systemMessage,
      },
      {
        role: "user",
        content: promptContext + prompt,
      },
    ],
  };
  // make post request be sure to handle errors, and return the response and the error
  try {
    const response = await axios.post(OPEN_AI_COMPLETIONS_URL, data, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const axiosError: AxiosError = err;
      throw new Error(axiosError.message);
    }
    throw new Error("Unknown error");
  }
};
