import styled from "styled-components";
import { getArticle, Character } from "./character";

const StyledPrompt = styled.div`
  font-size: 2.5em;
  font-family: "Nuernberg";
  text-align: center;
  padding: 20px;
  line-height: 1.5;

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledDescriptor = styled.a`
  color: #ea0e6c;
`;

const StyledArchetype = styled.a`
  color: #ea0e6c;
`;

const StyledFocus = styled.a`
  color: #ea0e6c;
`;

export const CharacterPrompt = ({
  character,
}: {
  character: Character | null;
}) => {
  const article = getArticle(character?.descriptor || "");

  return (
    <StyledPrompt>
      {character && (
        <>
          <span>You are {article} </span>
          <StyledDescriptor
            href={`https://whentitansfell.com/Descriptor#${character.descriptor
              .toLowerCase()
              .replaceAll(" ", "-")}`}
            target="_blank"
          >
            {character.descriptor}{" "}
          </StyledDescriptor>
          <StyledArchetype
            href={`https://whentitansfell.com/Archetype#the-${character.archetype
              .toLowerCase()
              .replaceAll(" ", "-")}`}
            target="_blank"
          >{character.archetype} </StyledArchetype>
          <span>who </span>
          <StyledFocus
            href={`https://whentitansfell.com/Focus#${character.focus
              .toLowerCase()
              .replaceAll(" ", "-")}`}
            target="_blank"
          >{character.focus}.</StyledFocus>
        </>
      )}
    </StyledPrompt>
  );
};
