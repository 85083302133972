import * as React from "react";
import styled from "styled-components";
import { Modal, ModalHeader, ModalBody, SIZE, ROLE } from "baseui/modal";
import { Input } from "baseui/input";
import { Combobox } from "baseui/combobox";
import { Character, archetypes, descriptors, foci } from "./character";

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: nowrap;
  font-family: "NKDuy";
  color: black;
  font-size: 20px;
  white-space: nowrap;
  font-weight: bold;
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
`;

const ComboBoxStyleOverrides = {
  Root: {
    style: () => ({
      backgroundColor: "black",
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
      fontFamily: "NKDuy",
    }),
  },
  Input: {
    props: {
      overrides: {
        Root: {
          style: () => ({
            backgroundColor: "black",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
          }),
        },
        Input: {
          style: () => ({
            backgroundColor: "black",
            fontFamily: "NKDuy",
          }),
        },
      },
    },
  },
  ListItem: {
    style: () => ({
      fontFamily: "NKDuy",
      ":hover": {
        backgroundColor: "white",
        color: "black",
      },
    }),
  },
  ListBox: {
    style: () => ({
      backgroundColor: "black",
      border: "1px solid white",
    }),
  },
};

export const SettingsModal = ({
  isOpen,
  setIsOpen,
  customCharacterDescriptor,
  customCharacterArchetype,
  customCharacterFocus,
  setCustomCharacterDescriptor,
  setCustomCharacterArchetype,
  setCustomCharacterFocus,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  customCharacterDescriptor: string;
  customCharacterArchetype: string;
  customCharacterFocus: string;
  setCustomCharacterDescriptor: (descriptor: string) => void;
  setCustomCharacterArchetype: (archetype: string) => void;
  setCustomCharacterFocus: (focus: string) => void;
}) => {

  return (
    <Modal
      onClose={() => setIsOpen(false)}
      closeable
      isOpen={isOpen}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Dialog: {
          style: ({ $theme }) => ({
            backgroundColor: "white",
            borderRadius: "0px",
          }),
        },
      }}
    >
      <ModalHeader>Hello world</ModalHeader>
      <ModalBody>
        <SettingsContainer>
          <InputContainer>
            <span>Character Descriptor</span>
            <Combobox
              onChange={(value) => setCustomCharacterDescriptor(value)}
              options={descriptors}
              mapOptionToString={(option) => option}
              value={customCharacterDescriptor}
              overrides={ComboBoxStyleOverrides}
            />
          </InputContainer>
          <InputContainer>
            <span>Character Archetype</span>
            <Combobox
              onChange={(value) => setCustomCharacterArchetype(value)}
              options={archetypes}
              mapOptionToString={(option) => option}
              value={customCharacterArchetype}
              overrides={ComboBoxStyleOverrides}
            />
          </InputContainer>
          <InputContainer>
            <span>Character Focus</span>
            <Combobox
              onChange={(value) => setCustomCharacterFocus(value)}
              options={foci}
              mapOptionToString={(option) => option}
              value={customCharacterFocus}
              overrides={ComboBoxStyleOverrides}
            />
          </InputContainer>
        </SettingsContainer>
      </ModalBody>
    </Modal>
  );
};
