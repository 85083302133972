import React from "react";
import styled from "styled-components";

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  top: 0;
  height: 150px;
  background-color: black;
  z-index: 1000;
  position: sticky;
`;

const TitleDiv = styled.div`
  font-family: "Lazar";
  font-size: 6em;
  color: white;
  word-spacing: 10px;
  cursor: pointer;
  word-spacing: 0px;

  @media (max-width: 450px) {
    font-size: 4em;
  }
`;

const Color = styled.span`
  color: #EA0E6C;
`;

export const Header = () => {
  return (
    <StyledHeader>
      <TitleDiv>
        WHEN <Color>TITANS</Color> FELL
      </TitleDiv>
    </StyledHeader>
  );
};
