import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const runes = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "L",
  "O",
  "P",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const getRandomRune = () => runes[Math.floor(Math.random() * runes.length)];

const animateRunes = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const MagicalLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const MagicalLoadingAnimation = styled.div`
  font-size: 42px;
  font-weight: bold;
  color: white; /* Change color as desired */
  display: flex;
  font-family: "AncientRunes";
`;

const RuneSpan = styled.span`
  width: 20px; /* Fixed width for each rune */
  margin: 0 3px;
  text-align: center;
  animation: ${animateRunes} 0.5s ease-in-out;
`;

export const RunicLoader = () => {
  const [loadingRunes, setLoadingRunes] = useState(
    Array.from({ length: 10 }, () => getRandomRune())
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingRunes((prevRunes) => {
        const newRunes = [...prevRunes];
        const indexToReplace = Math.floor(Math.random() * newRunes.length);
        newRunes[indexToReplace] = getRandomRune();
        return newRunes;
      });
    }, 50); // Adjust the interval as per your preference

    return () => clearInterval(interval);
  }, []);

  return (
    <MagicalLoadingContainer>
      <MagicalLoadingAnimation>
        {loadingRunes.map((rune, index) => (
          <RuneSpan key={index}>{rune}</RuneSpan>
        ))}
      </MagicalLoadingAnimation>
    </MagicalLoadingContainer>
  );
};
