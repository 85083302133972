import { useRef, useState } from "react";
import styled from "styled-components";

const sarcasticPhrases: string[] = [
  "Sure, another bland hero.",
  "Rolling the dice of fate... yawn.",
  "Oh, great. Another adventurer.",
  "Prepare for mediocrity.",
  "Another forgettable face in the crowd.",
  "Really? That's the best you can do?",
  "Your fate is sealed... and it's boring.",
  "A hero has been summoned. Big deal.",
  "Another day, another uninspired character.",
  "Congratulations, it's predictably average.",
  "You call that a character?",
  "Another cliché in the making.",
  "You've chosen... poorly.",
  "Are you even trying?",
  "Can't you do any better?",
  "Let's create your puppet!",
  "Destiny awaits... or not.",
  "Prepare to be utterly average!",
  "Just what the world needs, more heroes...",
  "Sure, let's create another abomination!",
  "What a brilliant idea... not.",
  "Hope you like clichés!",
  "RNGesus doesn't like you.",
  "A character only a mother could love.",
  "Behold! The pinnacle of average.",
  "You call that a character idea?",
  "Wow, what a masterpiece...",
];

const getRandomSarcasticPhrase = () => {
  return sarcasticPhrases[Math.floor(Math.random() * sarcasticPhrases.length)];
};

/* Styled button, plain white sqaure with black text */
const StyledButton = styled.button`
  background-color: black;
  color: white;
  border: 2px solid white;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
  cursor: pointer;
  font-family: "NKDuy";

  &:hover {
    background-color: white;
    color: black;
  }
  transition: all 0.2s ease-in-out;
`;

export const GenerateButton = ({ generate }: { generate: () => void }) => {
  const phrase = useRef(getRandomSarcasticPhrase());
  return (
    <StyledButton
      onClick={() => {
        generate();
        phrase.current = getRandomSarcasticPhrase();
      }}
    >
      {phrase.current}
    </StyledButton>
  );
};
